import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Table, Space, Typography } from 'antd';

const { Text } = Typography;

interface DataUser {
    gritId: string;
    username: string;
    countryCode: string;
    countryName: string;
}

interface BalanceData {
    usdt: string;
    gcredit: string;
    package: string;
    blackwallet: string;
    nft_credit: string;
    profit_sharing: string;
    activity_point: string;
    pv_left: string;
    pv_right: string;
}

interface Transaction {
    from: string;
    to: string;
    amount: string;
    cashtype: string;
    date: string;
}

interface TokenListData {
    amount: string;
    value: string;
}

interface TokenListHistoryData {
    transaction_id: string;
    date: string;
    token_amount: number;
    token_value: string;
    type: string;
    user_id: string;
}

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

export default function AddPoints() {
    const [addSource, setAddSource] = useState<string>('POINT');

    // Add Points
    const [searchValue, setSearchValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [dataUser, setDataUser] = useState<DataUser | null>(null);
    const [points, setPoints] = useState<number | ''>('');
    const [balanceData, setBalanceData] = useState<BalanceData | null>(null);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [adminData, setAdminData] = useState<Admin | null>(null);
    const [token, setToken] = useState<string | null>(null);

    // Add Tokens
    const [tokenList, setTokenList] = useState<TokenListData[]>([]);
    const [tokenListHistory, setTokenListHistory] = useState<TokenListHistoryData[]>([]);
    const [sellTokenPrice, setSellTokenPrice] = useState<any>(undefined);
    const [sellTokenAmount, setSellTokenAmount] = useState<any>(undefined);
    const [sellTokenUserId, setSellTokenUserId] = useState<any>(undefined);

    const columns = [
        {
            title: 'Token Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: string) => {
                // Format the amount with commas and add red text for negative values
                const formattedAmount = parseInt(amount).toLocaleString(); // Format number with commas
                const colorStyle = parseInt(amount) < 0 ? { color: 'red' } : {}; // Set text color for negatives

                return <span style={colorStyle}>{formattedAmount}</span>;
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <span className="cursor-pointer" onClick={() => setSellTokenPrice(record.value)}>
                        Sell Tokens
                    </span>
                </Space>
            ),
        },
    ];

    const columnsHistory = [
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Token Value',
            dataIndex: 'token_value',
            key: 'token_value',
        },
        {
            title: 'Token Amount',
            dataIndex: 'token_amount',
            key: 'token_amount',
            render: (amount: string) => {
                const formattedAmount = parseInt(amount).toLocaleString(); // Format number with commas

                return <span>{formattedAmount}</span>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text: any, record: any) => (
                <Text>
                    {new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        timeZone: 'UTC',
                    }).format(new Date(record.date))}
                </Text>
            ),
        },
    ];

    function onSwitchPage(source: string) {
        setAddSource(source);
        setSellTokenPrice(undefined);
        setSellTokenAmount(undefined);
        setSellTokenUserId(undefined);

        if (source === 'POINT_HISTORY') {
            getHistoryTransaction();
        } else if (source === 'GTOKEN') {
            getTokenList();
            getTokenHistoryList();
        }
    }

    function inputValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.value;
        setSearchValue(value);
    }

    function handleSelection(option: string): void {
        setSelectedOption(option);
    }

    function handlePointsChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        setPoints(value === '' ? '' : parseFloat(value));
    }

    function getAdminData(): any {
        const token = localStorage.getItem('token');

        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const data = res.data;
                setAdminData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function searchUser() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/fetch-user?uid=${searchValue}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setDataUser(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function searchBalance() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/fetch-user-wallet?uid=${searchValue}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setBalanceData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleSearch() {
        searchUser();
        searchBalance();
    }

    function savePoints() {
        if (dataUser && points && selectedOption) {
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_API}/manage/update-wallet`,
                    {
                        userId: dataUser.gritId,
                        cash: points,
                        cashType: selectedOption,
                        adminUsername: adminData?.uname,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((res) => {
                    const gcredit = `${parseFloat(res.data.gcredit) < 0 ? `-` : ''}$${Math.abs(
                        parseFloat(res.data.gcredit)
                    ).toLocaleString()}`;
                    const usdt = `${parseFloat(res.data.usdt) < 0 ? `-` : ''}$${Math.abs(parseFloat(res.data.usdt)).toLocaleString()}`;
                    const nft = `${parseFloat(res.data.nft) < 0 ? `-` : ''}$${Math.abs(parseFloat(res.data.nft)).toLocaleString()}`;
                    const sharing = `${parseFloat(res.data.sharing) < 0 ? `-` : ''}$${Math.abs(
                        parseFloat(res.data.sharing)
                    ).toLocaleString()}`;
                    const blackwallet = `${parseFloat(res.data.blackwallet) < 0 ? `-` : ''}$${Math.abs(
                        parseFloat(res.data.blackwallet)
                    ).toLocaleString()}`;
                    const activityPoint = `${parseFloat(res.data.activity_point) < 0 ? `-` : ''}$${Math.abs(
                        parseFloat(res.data.activity_point)
                    ).toLocaleString()}`;

                    const pvLeft = `${parseFloat(res.data.pv_left) < 0 ? `-` : ''}$${Math.abs(
                        parseFloat(res.data.pv_left)
                    ).toLocaleString()}`;
                    const pvRight = `${parseFloat(res.data.pv_right) < 0 ? `-` : ''}$${Math.abs(
                        parseFloat(res.data.pv_right)
                    ).toLocaleString()}`;
                    const userId = res.data.user_id;

                    let html;
                    if (res.data.usdt) {
                        html = `G-Credit = ${gcredit}<br>USDT Credit = ${usdt}<br>NFTs Credit = ${nft}<br>My Wallet = ${blackwallet}<br>Profit Sharing Wallet = ${sharing}<br>Activity Point = ${activityPoint}<br>GRIT ID = ${userId}`;
                    } else if (res.data.pv_left) {
                        html = `Binary PV Left = ${pvLeft}<br>Binary PV Right = ${pvRight}<br>GRIT ID = ${userId}`;
                    }

                    Swal.fire({
                        title: 'Add Points สำเร็จ',
                        html,
                        icon: 'success',
                    });

                    handleSearch();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            console.log('Please select a user, point type, and enter points value.');
        }
    }

    function getCountryFlagURL(countryCode: string): string {
        return `/imgs/flags/${countryCode.toLowerCase()}.svg`;
    }

    function getHistoryTransaction() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/transfer/log`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const transactions: Transaction[] = res.data; // Assuming res.data is an array of transactions
                setTransactions(transactions.reverse());
                // Optionally, you can set state or process data further
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getTokenList() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/token`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const tokenList: TokenListData[] = res.data.data;
                setTokenList(tokenList);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getTokenHistoryList() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/token/history`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const tokenListHistory: TokenListHistoryData[] = res.data.data;
                setTokenListHistory(tokenListHistory);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function sellGToken() {
        if (sellTokenPrice && sellTokenAmount && sellTokenUserId) {
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_API}/manage/token`,
                    {
                        userId: sellTokenUserId,
                        tokenAmount: parseInt(sellTokenAmount),
                        tokenValue: parseFloat(sellTokenPrice),
                        adminUsername: adminData?.uname,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((res) => {
                    getTokenList();
                    getTokenHistoryList();
                    setSellTokenPrice(undefined);
                    setSellTokenAmount(undefined);
                    setSellTokenUserId(undefined);

                    Swal.fire({
                        title: 'Sell G-Tokens สำเร็จ',
                        icon: 'success',
                    });
                })
                .catch((err) => {
                    console.log(err);
                    Swal.fire({
                        title: 'Error',
                        html: err?.response?.data?.message,
                        icon: 'error',
                    });
                });
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (token) {
            getAdminData();
        }
    }, [token]);

    return (
        <main className="w-full h-[1000px]">
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">Add Points & Token | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex flex-col flex-auto mt-2 overflow-y-auto bg-haft pb-2">
                <div className="w-full flex justify-center">
                    <div className="flex items-center mt-2 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700 text-sm font-bold lg:font-medium">
                        <button
                            className={`px-1 rounded-full w-56 h-16 lg:h-10 ${
                                addSource === 'POINT' ? 'shadow bg-purple-700 text-white pointer-events-none' : 'bg-transparent'
                            }`}
                            onClick={() => onSwitchPage('POINT')}
                        >
                            Add Points
                        </button>
                        <button
                            className={`px-1 rounded-full w-56 h-16 lg:h-10 ${
                                addSource === 'POINT_HISTORY' ? 'shadow bg-purple-700 text-white pointer-events-none' : 'bg-transparent'
                            }`}
                            onClick={() => onSwitchPage('POINT_HISTORY')}
                        >
                            Add Points History
                        </button>
                        <button
                            className={`px-1 rounded-full w-56 h-16 lg:h-10 ${
                                addSource === 'GTOKEN' ? 'shadow bg-purple-700 text-white pointer-events-none' : 'bg-transparent'
                            }`}
                            onClick={() => onSwitchPage('GTOKEN')}
                        >
                            Add G-Token
                        </button>
                    </div>
                </div>
            </div>

            {addSource === 'POINT' && (
                <>
                    <div className="flex w-10/12 md:w-8/12 mx-auto mt-4">
                        <input
                            type="text"
                            placeholder="ค้นหา GRIT ID"
                            className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl"
                            onChange={(e) => inputValue(e)}
                        />
                        <button className="ml-4 bg-purple-500 text-white rounded-xl px-4" onClick={handleSearch}>
                            Search
                        </button>
                    </div>

                    <div className="w-10/12 md:w-8/12 h-auto flex mt-2.5 mx-auto justify-center items-center">
                        <div className="w-full rounded-xl bg-gray-200 h-auto p-2.5  flex items-center justify-center md:h-96">
                            {dataUser && balanceData ? (
                                <div className="flex flex-col justify-center items-center w-full h-full md:flex-row">
                                    <div className="w-full h-full  flex flex-col text-xl justify-center p-2.5 px-10">
                                        <p>GRIT ID: {dataUser.gritId}</p>
                                        <p>Username: {dataUser.username}</p>
                                        <p>Package: {balanceData.package}</p>
                                        <p>
                                            Country: {dataUser.countryName} ({dataUser.countryCode})
                                            <img
                                                src={getCountryFlagURL(dataUser.countryCode)}
                                                alt={`${dataUser.countryCode} flag`}
                                                className="inline-block ml-2"
                                                style={{ width: '40px', height: '40px' }}
                                            />
                                        </p>
                                    </div>
                                    <div className="w-full h-full flex flex-col justify-center items-center text-xl mb-2.5 md:mb-0">
                                        {/* <h1 className="mt-3 w-max mx-auto">สถานะการเงิน</h1> */}
                                        <p className="w-10/12 mx-auto  flex justify-center items-center text-white bg-purple-200 h-10 rounded-lg">{`G-Credit: ${
                                            parseFloat(balanceData.gcredit) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.gcredit)).toLocaleString()}`}</p>
                                        <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-10 rounded-lg">{`USDT Credit: ${
                                            parseFloat(balanceData.usdt) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.usdt)).toLocaleString()}`}</p>
                                        <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-10 rounded-lg">{`NFTs Credit: ${
                                            parseFloat(balanceData.nft_credit) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.nft_credit)).toLocaleString()}`}</p>
                                        <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-10 rounded-lg">{`My Wallet: ${
                                            parseFloat(balanceData.blackwallet) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.blackwallet)).toLocaleString()}`}</p>
                                        <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-10 rounded-lg">{`Profit Sharing Wallet: ${
                                            parseFloat(balanceData.profit_sharing) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.profit_sharing)).toLocaleString()}`}</p>
                                        <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-10 rounded-lg">{`Activity Point: ${
                                            parseFloat(balanceData.activity_point) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.activity_point)).toLocaleString()}`}</p>
                                        <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-10 rounded-lg">{`Remaining PV Left: ${
                                            parseFloat(balanceData.pv_left) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.pv_left)).toLocaleString()}`}</p>
                                        <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-10 rounded-lg">{`Remaining PV Right: ${
                                            parseFloat(balanceData.pv_right) < 0 ? `-` : ''
                                        }$${Math.abs(parseFloat(balanceData.pv_right)).toLocaleString()}`}</p>
                                    </div>
                                </div>
                            ) : (
                                <p>No user data found</p>
                            )}
                        </div>
                    </div>

                    <div className="flex w-10/12 md:w-8/12 mx-auto mt-4">
                        <input
                            type="number"
                            placeholder="Enter points"
                            className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl"
                            value={points}
                            onChange={handlePointsChange}
                        />
                    </div>

                    <div className="flex flex-col md:flex-row w-10/12 md:w-8/12 mx-auto mt-4 gap-x-2.5">
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'GCREDIT' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('GCREDIT')}
                        >
                            G-Credit
                        </button>
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'USDT' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('USDT')}
                        >
                            USDT Credit
                        </button>
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'NFT' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('NFT')}
                        >
                            NFTs Credit
                        </button>
                    </div>

                    <div className="flex flex-col md:flex-row w-10/12 md:w-8/12 mx-auto gap-x-2.5 mt-0 sm:mt-4">
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'MYWALLET' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('MYWALLET')}
                        >
                            My Wallet
                        </button>
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'SHARING' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('SHARING')}
                        >
                            Profit Sharing
                        </button>
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'AP' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('AP')}
                        >
                            Activity Point
                        </button>
                    </div>

                    <div className="flex flex-col md:flex-row w-10/12 md:w-8/12 mx-auto mt-4 gap-x-2.5">
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'L_BINARY' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('L_BINARY')}
                        >
                            Binary Left
                        </button>
                        <button
                            className={`h-12 mt-2 md:mt-0 w-full outline-0 text-center rounded-xl ${
                                selectedOption === 'R_BINARY' ? 'bg-purple-500 text-white' : 'bg-gray-200'
                            }`}
                            onClick={() => handleSelection('R_BINARY')}
                        >
                            Binary Right
                        </button>
                    </div>

                    <div className="flex w-10/12 md:w-8/12 mx-auto mt-4">
                        <button className="h-12 w-full bg-black text-white rounded-xl" onClick={savePoints}>
                            Save Points
                        </button>
                    </div>
                </>
            )}

            {addSource === 'POINT_HISTORY' && (
                <>
                    <h2 className="text-xl font-semibold mb-2 text-center py-2">Transaction History</h2>
                    <div className="w-10/12 max-h-96 lg:max-h-full overflow-y-auto mx-auto mt-5 md:w-8/12">
                        {transactions.map((transaction: any, index) => (
                            <div key={index} className="flex justify-between py-2 border-b">
                                <div>
                                    <p>
                                        <strong>From:</strong> {transaction.from}
                                    </p>
                                    <p>
                                        <strong>To:</strong> {transaction.to}
                                    </p>
                                    <p>
                                        <strong>Amount:</strong>{' '}
                                        {`${transaction.amount < 0 ? `-` : ''}$${Math.abs(
                                            parseFloat(transaction.amount)
                                        ).toLocaleString()}`}
                                    </p>
                                    <p>
                                        <strong>Cash Type:</strong> {transaction.cashtype}
                                    </p>
                                    <p>
                                        <strong>Date:</strong> {new Date(transaction.date).toLocaleString()}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {addSource === 'GTOKEN' && (
                <>
                    <div className="flex w-10/12 md:w-8/12 mx-auto mt-4">
                        <Table className="w-full" columns={columns} pagination={{ position: ['bottomCenter'] }} dataSource={tokenList} />
                    </div>
                    {sellTokenPrice && (
                        <>
                            <div className="flex flex-col mt-2 justify-center">
                                <h4 className="flex w-full md:w-8/12 mx-auto mt-4 items-center font-bold text-xl">Sell G-Tokens</h4>
                                <div className="flex w-full md:w-8/12 mx-auto mt-4">
                                    <div className="w-48 flex justify-start items-center">Token Price:</div>
                                    <input
                                        type="number"
                                        className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl"
                                        value={sellTokenPrice}
                                        disabled
                                    />
                                </div>
                                <div className="flex w-full md:w-8/12 mx-auto mt-4">
                                    <div className="w-48 flex justify-start items-center">Token Amount:</div>
                                    <input
                                        type="number"
                                        placeholder="Enter Token Amount"
                                        className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl"
                                        onChange={(e) => setSellTokenAmount(e.target.value)}
                                    />
                                </div>
                                <div className="flex w-full md:w-8/12 mx-auto mt-4">
                                    <div className="w-48 flex justify-start items-center">User ID:</div>
                                    <input
                                        type="text"
                                        placeholder="Enter User ID"
                                        className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl"
                                        onChange={(e) => setSellTokenUserId(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="flex w-1/2 md:w-1/3 mx-auto mt-4">
                                <button className="h-12 w-full bg-black text-white rounded-xl" onClick={sellGToken}>
                                    Add Tokens
                                </button>
                            </div>
                        </>
                    )}

                    <div className="flex flex-col w-full md:w-8/12 mx-auto mt-12">
                        <h4 className="mt-4 ml-4 md:ml-0 items-start justify-start font-bold text-xl">Transactions History</h4>
                        <Table
                            className="w-full mt-4"
                            columns={columnsHistory}
                            pagination={{ position: ['bottomCenter'] }}
                            dataSource={tokenListHistory}
                        />
                    </div>
                </>
            )}
        </main>
    );
}
