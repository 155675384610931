import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import LineNotify from "../../utility/LineNotify";

type Props = {};

interface User {
  user_id: string;
  package: string;
  cash: string;
  gcredit: string;
  roi: string;
  is_active: boolean;
  country_code: string;
  username : string | null;
  wallet_address : string | null;
  country_name : string;
}

interface allDataUserClicked {
  user_id: string | null;
  package: string | null;
  cash: string | null;
  gcredit: string | null;
  roi: string | null;
  is_active: boolean | null;
  country_code: string | null;
  username : string | null;
  wallet_address : string | null;
  country_name : string;

}

export default function Wallet({}: Props) {
  const [inputValue, setInputValue] = useState("");
  const token = localStorage.getItem("token");
  const [dataUsers, setDataUsers] = useState<User[]>([]);
  const [selectedUID, setSelectedUID] = useState("");
  const [newWallet , setNewWallet] = useState("");
  const [allDataOfUserClicked , setAllDataOfUserClicked] = useState<allDataUserClicked>({
    user_id : null,
    package : null,
    cash : null,
    gcredit : null,
    roi : null,
    is_active : null,
    country_code : null,
    username : null,
    wallet_address : null,
    country_name : ''
  })
  function getUser() {
    axios
      .get(
        `${process.env.REACT_APP_ADMIN_API}/etc/list-users?uid=${inputValue}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res: any) => {
        console.log("data user :", res.data);
        setDataUsers(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  function handleSelectUser(uid: string, countryCode: string , allData : any) {
    setSelectedUID(uid);
    setAllDataOfUserClicked(allData)
  }

  function confirmChangeWallet(){
    if(selectedUID !== "" && newWallet !== ""){
      axios.post(`${process.env.REACT_APP_ADMIN_API}/manage/update/edit-new-metamask-wallet`,{
        grit_id : selectedUID,
        newWalletAddress : newWallet
      },{
        headers :{
          Authorization : `Bearer ${token}`
        }
      }).then((res)=>{
        Swal.fire(``,`อัพเดท Wallet สำเร็จ ${newWallet}` , 'success');
        if(allDataOfUserClicked){
          const message = `\n\nAdmin: gritadmin\n\nEdit Wallet\nCountry : ${allDataOfUserClicked.country_name } (${allDataOfUserClicked.country_code})\ngritID : ${allDataOfUserClicked.user_id}\nUsername : ${allDataOfUserClicked.username}\n\n\n(Old) Wallet Address: ${allDataOfUserClicked.wallet_address}\n\n(New) wallet Address : ${newWallet}`;
          LineNotify(message);
        }
    
      }).catch((err)=>{
        console.log(err)
        Swal.fire(`` , `${err.response.data.error} ,` , 'error');
      })
    }
  
}
  useEffect(() => {
    getUser();
  }, [inputValue]);
  return (
    <div className="w-full h-screen">
      <div className="w-10/12 h-full mx-auto mt-16">
        <h1 className="text-3xl font-bold">Edit Wallet User</h1>
        <img src="/imgs/icons/3258446.png" className="w-24 h-24 my-3" alt="icon"/>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search User"
          className="w-full text-center  border  px-3 py-2  mb-4 outline-none rounded-full"
        />

        <div className="grid grid-cols-1 gap-2.5 w-full h-[400px] overflow-y-auto md:grid-cols-4">
          {dataUsers.map((data, idx) => {
            return (
              <div
                className={`w-full h-[200px] border p-2.5  rounded-xl cursor-pointer ${
                  selectedUID == data.user_id
                    ? "bg-purple-400 hover:bg-purple-400 text-white"
                    : "hover:bg-gray-300"
                }`}
                onClick={() => handleSelectUser(data.user_id, data.country_code , data)}
              >
                <p>User ID : {data.user_id}</p>
                <p>ROI : {data.roi}</p>
                <p>G-Credit : {data.gcredit}</p>
                <p>Cash : {data.cash}</p>
                <p>Now Package : {data.package}</p>
                <p className="">Active : {data.is_active ? "true" : "false"}</p>
              </div>
            );
          })}
        </div>


        <div className="w-full h-auto ">
            <h1 className="text-xl mt-2.5">New Wallet Address (41 character)</h1>
            <input onChange={(e)=>setNewWallet(e.target.value)} type="text" placeholder="new wallet address" className="h-12 w-full rounded-xl text-center outline-none"/>
        </div>


        <div onClick={()=>confirmChangeWallet()} className="w-full h-12 bg-black text-white text-xl rounded-full flex justify-center items-center mt-8">Confirm</div>
      </div>
    </div>
  );
}
