import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

// Define the interface for DataUser
interface DataUser {
    gritId: string;
    username: string;
    countryCode: string;
    countryName: string;
    walletAddress: string;
    packageClass: string;
    email: string;
    referral: string;
    isAllSaleBinary: boolean;
    packageValue: number;
    packageValueNoPv: number;
    withdrawLimit: number;
    withdrawLimitVip: boolean;
    withdrawLimitNft: boolean;
    withdrawalGroup: number;
    privilegeClass: string;
}

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

export default function EditProfile() {
    const [searchValue, setSearchValue] = useState('');
    const [dataUser, setDataUser] = useState<DataUser | null>(null);
    const [adminData, setAdminData] = useState<Admin | null>(null);
    const [token, setToken] = useState<string | null>(null);

    const dayList = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    function inputValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.value;
        setSearchValue(value);
    }

    function getAdminData(): any {
        const token = localStorage.getItem('token');

        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                const data = res.data;
                setAdminData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function searchUser() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/fetch-user?uid=${searchValue}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                setDataUser(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleSearch() {
        if (searchValue) {
            searchUser();
        }
    }

    function updateReferral() {
        if (dataUser) {
            Swal.fire({
                title: 'Enter new Referral User ID',
                input: 'text',
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/referral`,
                            {
                                gritId: dataUser?.gritId,
                                newReferralId: result.value,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update Referral User ID สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function updateUserId() {
        if (dataUser) {
            Swal.fire({
                title: 'Enter new User ID',
                input: 'text',
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-id`,
                            {
                                gritId: dataUser?.gritId,
                                newId: result.value,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update User ID สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function updateUsername() {
        if (dataUser) {
            Swal.fire({
                title: 'Enter new Username',
                input: 'text',
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                newUsername: result.value,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update Username สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function updateWalletAddress() {
        if (dataUser) {
            Swal.fire({
                title: 'Enter new Wallet Address',
                input: 'text',
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                newWalletAddress: result.value,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update Wallet Address สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function resetWalletAddress() {
        if (dataUser) {
            Swal.fire({
                title: 'Reset USDT Wallet Address',
                text: 'ยืนยันเพื่อ Reset USDT Wallet Address',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                resetWalletAddress: true,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            Swal.fire({
                                title: 'Reset USDT Wallet สำเร็จ',
                                icon: 'success',
                            });

                            setSearchValue('');
                            setDataUser(null);
                        })
                        .catch((err) => {
                            Swal.fire(`${err.message}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function updateEmailAddress() {
        if (dataUser) {
            Swal.fire({
                title: 'Enter new Email',
                input: 'text',
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                newEmail: result.value,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update Email สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function resetPassword() {
        if (dataUser) {
            Swal.fire({
                title: 'Reset Password',
                text: 'ยืนยันเพื่อ Reset Password',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                resetPassword: true,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            Swal.fire({
                                title: 'Reset Password สำเร็จ',
                                text: `New password: GRIT${dataUser?.gritId}`,
                                icon: 'success',
                            });

                            setSearchValue('');
                            setDataUser(null);
                        })
                        .catch((err) => {
                            Swal.fire(`${err.message}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function resetAccount() {
        if (dataUser) {
            Swal.fire({
                title: 'Reset Account',
                text: 'ยืนยันเพื่อ Reset Account',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                resetAccount: true,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            Swal.fire({
                                title: 'Reset Account สำเร็จ',
                                icon: 'success',
                            });

                            setSearchValue('');
                            setDataUser(null);
                        })
                        .catch((err) => {
                            Swal.fire(`${err.message}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function resetPackageNoPV() {
        if (dataUser) {
            Swal.fire({
                title: 'Reset Package PV 0',
                text: 'ยืนยันเพื่อ Reset Package PV 0',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                resetNoPvPackage: true,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            Swal.fire({
                                title: 'Reset Account สำเร็จ',
                                icon: 'success',
                            });

                            setSearchValue('');
                            setDataUser(null);
                        })
                        .catch((err) => {
                            Swal.fire(`${err.message}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function allSalesBinary() {
        if (dataUser) {
            Swal.fire({
                title: `Update All Sales binary to ${(!dataUser.isAllSaleBinary).toString().toUpperCase()}`,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                allSales: true,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update All Sales Binary สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function updateWithdrawLimit() {
        if (dataUser) {
            Swal.fire({
                title: 'Enter new Withdraw Limit Multiply',
                input: 'text',
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                newWithdrawLimit: parseInt(result.value),
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update Withdraw Limit สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function WithdrawLimitVIP() {
        if (dataUser) {
            Swal.fire({
                title: `Update Withdraw Limit VIP to ${(!dataUser.withdrawLimitVip).toString().toUpperCase()}`,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                withdrawLimitVip: true,
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update Withdraw Limit VIP สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    function updateWithdrawGroup() {
        if (dataUser) {
            Swal.fire({
                title: 'Enter new Withdraw Group (1-7)',
                input: 'text',
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed && dataUser && result.value) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                            {
                                gritId: dataUser?.gritId,
                                newWithdrawGroup: parseInt(result.value),
                                adminUsername: adminData?.uname,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        )
                        .then(() => {
                            setSearchValue('');
                            setDataUser(null);

                            Swal.fire({
                                title: 'Update Withdraw Group สำเร็จ',
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            Swal.fire(`${err.response.data.error}`, '', 'error');
                            console.log(err);
                        });
                }
            });
        }
    }

    async function updatePrivilegeClass() {
        if(!dataUser) {
            return;
        }

        await Swal.fire({
            title: 'Update Privilege Class',
            input: 'select',
            inputOptions: {
                NONE: 'NONE',
                GRITIVITY: 'GRITIVITY',
            },
            inputPlaceholder: 'Select a class',
            showCancelButton: true,
            showClass: {
                backdrop: 'swal2-noanimation',
                popup: '',
                icon: '',
            },
            hideClass: {
                popup: '',
            },
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value) {
                        resolve();
                    } else {
                        resolve('You need to select a class');
                    }
                });
            },
        }).then((result) => {            
            if (result.isConfirmed && dataUser && result.value) {
                axios
                    .post(
                        `${process.env.REACT_APP_ADMIN_API}/manage/update/user-profile`,
                        {
                            gritId: dataUser?.gritId,
                            privilegeClass: result.value,
                            adminUsername: adminData?.uname,
                        },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then(() => {
                        searchUser()

                        Swal.fire({
                            title: 'Update privilege class successful',
                            icon: 'success',
                            showClass: {
                                backdrop: 'swal2-noanimation',
                                popup: '',
                                icon: '',
                            },
                            hideClass: {
                                popup: '',
                            },
                        });
                    })
                    .catch((err) => {
                        Swal.fire(`${err.response.data.error}`, '', 'error');
                        console.log(err);
                    });
            }
        });;

    }

    function getCountryFlagURL(countryCode: string): string {
        return `/imgs/flags/${countryCode.toLowerCase()}.svg`;
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (token) {
            getAdminData();
        }
    }, [token]);

    return (
        <main className={`w-full h-[1000px]`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">Edit Profile | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-4 ">
                <input
                    value={searchValue}
                    type="text"
                    placeholder="ค้นหา GRIT ID"
                    className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl"
                    onChange={(e) => inputValue(e)}
                />
                <button className="ml-4 bg-purple-500 text-white rounded-xl px-4" onClick={handleSearch}>
                    Search
                </button>
            </div>

            <div className="w-10/12 md:w-8/12 h-auto flex mt-2.5 mx-auto justify-center items-center">
                <div className="w-full rounded-xl bg-gray-200 h-auto p-2.5  flex items-center justify-center md:h-[28rem]">
                    {dataUser ? (
                        <div className="flex flex-col justify-center items-center w-full h-full md:flex-row">
                            <div className="w-full h-full ml-4 flex flex-col text-xl justify-center p-2.5">
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">GRIT ID :</span> {dataUser.gritId}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Username :</span> {dataUser.username}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Package :</span> {dataUser.packageClass}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Email :</span> {dataUser.email}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Wallet Address :</span> {dataUser.walletAddress}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Referral :</span> {dataUser.referral || '-'}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">All Sales Binary :</span>{' '}
                                    {dataUser.isAllSaleBinary?.toString().toUpperCase()}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Package value :</span> {dataUser.packageValue?.toLocaleString()}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Package value (PV 0) :</span>{' '}
                                    {dataUser.packageValueNoPv?.toLocaleString()}
                                </p>
                                <p className="text-sm sm:text-l">
                                    <span className="font-semibold">Country :</span> {dataUser.countryName} ({dataUser.countryCode})
                                    <img
                                        src={getCountryFlagURL(dataUser.countryCode)}
                                        alt={`${dataUser.countryCode} flag`}
                                        className="inline-block ml-2"
                                        style={{ width: '30px', height: '30px' }}
                                    />
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Withdraw Limit :</span> {dataUser.withdrawLimit}x
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Withdraw Limit NFT :</span>{' '}
                                    {dataUser.withdrawLimitNft?.toString().toUpperCase()}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Withdraw Limit VIP :</span>{' '}
                                    {dataUser.withdrawLimitVip?.toString().toUpperCase()}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Withdraw Group :</span>{' '}
                                    {dataUser.withdrawalGroup
                                        ? dataUser.withdrawalGroup + ' - ' + dayList[dataUser.withdrawalGroup - 1]
                                        : 'N/A'}
                                    {dataUser.withdrawalGroup && (
                                        <img
                                            src={`/imgs/withdrawal-group/group-${dataUser.withdrawalGroup}.png`}
                                            alt={`${dataUser.withdrawalGroup} group`}
                                            className="inline-block ml-2"
                                            style={{ width: '30px', height: '30px' }}
                                        />
                                    )}
                                </p>
                                <p className="text-sm sm:text-l py-1">
                                    <span className="font-semibold">Privilege Class :</span> {dataUser.privilegeClass}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <h2>No user data found</h2>
                    )}
                </div>
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-4 grid-cols-2 gap-2">
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updateReferral}>
                    Update Referral
                </button>
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updateUserId}>
                    Update User ID
                </button>
            </div>
            <div className="flex w-10/12 md:w-8/12 mx-auto mt-2 grid-cols-2 gap-2">
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updateUsername}>
                    Update Username
                </button>
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updateWalletAddress}>
                    Edit Wallet Address
                </button>
            </div>
            <div className="flex w-10/12 md:w-8/12 mx-auto mt-2 grid-cols-2 gap-2">
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={resetWalletAddress}>
                    Reset Wallet Address
                </button>
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updateEmailAddress}>
                    Edit Email Address
                </button>
            </div>
            <div className="flex w-10/12 md:w-8/12 mx-auto mt-2 grid-cols-2 gap-2">
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={resetAccount}>
                    Reset Account
                </button>
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={resetPackageNoPV}>
                    Reset Package PV 0
                </button>
            </div>
            <div className="flex w-10/12 md:w-8/12 mx-auto mt-2 grid-cols-2 gap-2">
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={resetPassword}>
                    Reset Password
                </button>
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={allSalesBinary}>
                    Mark All Sales Binary
                </button>
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-2 grid-cols-2 gap-2">
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updateWithdrawLimit}>
                    Update Withdraw Limit
                </button>
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={WithdrawLimitVIP}>
                    Mark Withdraw Limit VIP
                </button>
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-2 grid-cols-2 gap-2 pb-2">
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updateWithdrawGroup}>
                    Update Withdraw Group
                </button>
                <button className="h-12 w-full bg-purple-300 text-white rounded-xl" onClick={updatePrivilegeClass}>
                    Update Privilege Class
                </button>
            </div>
        </main>
    );
}
